import GLightbox from "glightbox";

class VideoModal {
    constructor(selector) {
        this.selector = selector;
        console.log(this.selector.tagName)
        if (this.selector.tagName !== 'A') {
            this.selector = this.selector.querySelector('a');
        }

        if (this.selector !== null) {
            this.selector.addEventListener('click', this._clickLink);
        }
    }

    _clickLink(event) {
        event.preventDefault();
        let video_url = this.getAttribute('href');
        console.log(video_url);
        if (video_url !== '') {
            const myGallery = GLightbox({
                elements: [
                    {
                        'href': video_url,
                        'type': 'video',
                        'source': 'youtube',
                    }
                ],
                autoplayVideos: true,
            });
            myGallery.open();
        }
    }
}

export default VideoModal;